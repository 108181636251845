/* global Cookies, attachEvent */

const cookieElement = document.querySelector('.cookie-banner');
const loadScripts = () => {
  const scripts = document.querySelectorAll('.sp-cookies.gtm');
  scripts.forEach((script) => {
    script.type = 'text/javascript';
    document.head.appendChild(script);
  });
};

if (cookieElement) {
  if (!Cookies.get('cookie-banner-seen')) {
    cookieElement.classList.add('cookie-not-set');
  }

  attachEvent('.btn--cookie', 'click', (ev) => {
    ev.preventDefault();
    loadScripts();
    Cookies.set('cookie-banner-seen', 'cookie-set', { expires: 7 });
    cookieElement.classList.remove('cookie-not-set');
  });
}

if (!cookieElement) {
  loadScripts();
}
