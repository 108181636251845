// **********************************************************
// Main menu dropdown
// **********************************************************
attachEvent('.menu__arrow', 'click', (ev) => {
  if (windowWidth() < breakpointMd) {
    const arrowParent = ev.target.closest('.menu__item');
    const menuDropdown = arrowParent.querySelectorAll('.menu__dropdown')[0];

    if (arrowParent.classList.contains('is-open-submenu')) {
      slideUp(menuDropdown, 300);
      arrowParent.classList.remove('is-open-submenu');
    } else {
      arrowParent.classList.add('is-open-submenu');
      slideDown(menuDropdown, 300);
    }
  }
});
