document.addEventListener('DOMContentLoaded', () => {
  const forms = document.querySelectorAll('.form-init');

  forms.forEach((form) => {
    const fv = FormValidation.formValidation(form, {
      locale: 'sl_SI',
      localization: FormValidation.locales.sl_SI,
      fields: {},
      plugins: {
        message: new FormValidation.plugins.Message({
          container: (field, element) =>
            element.closest('.mod-input,.checkbox-group, .form-module__final-checkboxes .checkbox'),
        }),
        declarative: new FormValidation.plugins.Declarative({
          html5Input: true,
        }),
        trigger: new FormValidation.plugins.Trigger(),
        submitButton: new FormValidation.plugins.SubmitButton(),
      },
    })
      .on('core.element.validated', (e) => {
        // e.element presents the field element
        // e.valid indicates the field is valid or not

        // Get the field value
        const value = fv.getElementValue(e.field, e.element);
        const container = FormValidation.utils.closest(
          e.element,
          '.mod-input,.checkbox-group, .form-module__final-checkboxes .checkbox',
        );
        if (!e.valid && value === '') {
          FormValidation.utils.classSet(container, {
            'is-validation-failed': true,
          });
        } else {
          FormValidation.utils.classSet(container, {
            'is-validation-failed': false,
          });
        }

        FormValidation.utils.classSet(container, {
          'is-validation-failed': !e.valid,
        });
      })
      .on('core.form.valid', () => {
        if (form.classList.contains('form-module__top')) {
          form.classList.add('show-thankyou');
          document.querySelector('.form-module__bottom').scrollIntoView({ behavior: 'smooth' });
          document.querySelector('.form-module__bottom').classList.add('is-ready');
        } else {
          form.classList.add('is-validated');
        }
      });
  });
});
