// *******************************************************************************
// 1.0 - Gallery Modal
// *******************************************************************************

const galleryModalElements = document.querySelectorAll('[data-slider="gallery-modal-slider"]');

const galleryModalOptions = {
  slidesPerView: 1,
  spaceBetween: 0,
  effect: 'fade',
  fadeEffect: {
    crossFade: true,
  },
  navigation: {
    nextEl: '.swiper-button-prev',
    prevEl: '.swiper-button-next',
  },
};

window.onload = () => {
  document.querySelectorAll('.gallery-modal').forEach((el) => {
    el.removeAttribute('hidden');
  });

  galleryModalElements.forEach((el) => {
    const galleryModalParent = el.closest('.gallery-modal');
    const arrowRight = galleryModalParent.querySelector('.arrow-right');
    const arrowLeft = galleryModalParent.querySelector('.arrow-left');

    galleryModalOptions.navigation.nextEl = arrowRight;
    galleryModalOptions.navigation.prevEl = arrowLeft;
    const galleryModal = new Swiper(el, galleryModalOptions);

    attachEvent('.image-gallery-card', 'click', (ev) => {
      const targetSlide = ev.target.getAttribute('data-modalslide');
      galleryModal.slideTo(targetSlide - 1, 0);
    });
  });
};

// *******************************************************************************
// 2.0 - Close Gallery-modal
// *******************************************************************************

attachEvent('.gallery-modal__close', 'click', (ev) => {
  const parentElem = ev.target.closest('.gallery-modal');
  parentElem.classList.remove('is-open-gallery-modal');
});

// Close tv fullscreen
document.addEventListener('keydown', (event) => {
  if (event.key === 'Escape') {
    document.querySelectorAll('.gallery-modal.is-open-gallery-modal').forEach((el) => {
      el.classList.remove('is-open-gallery-modal');
    });
  }
});
