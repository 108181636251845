// *******************************************************************************
// 1.0 - open Gallery-modal
// *******************************************************************************

attachEvent('.image-gallery-card', 'click', (ev) => {
  const parent = ev.target.closest('.image-gallery-wrapper');
  const galleryModal = parent.querySelector('.gallery-modal');
  galleryModal.classList.add('is-open-gallery-modal');
  ev.preventDefault();
});
