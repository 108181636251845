/* global Cookies, attachEvent,slideUp, slideDown */

const advanceCookieElement = document.querySelector('.advance-cookie-banner-wrapper');

function setCookie(element) {
  const cookieValue = element.value === 'yes' || element.checked ? 'cookie-set' : 'cookie-not-set';
  Cookies.set('advance-cookie-banner-seen', cookieValue, { expires: 7 });
}

if (advanceCookieElement) {
  if (!Cookies.get('advance-cookie-banner-seen')) {
    advanceCookieElement.classList.add('cookie-not-set');
  }

  attachEvent('.btn--cookie', 'click', (ev) => {
    ev.preventDefault();
    const optionEle = document.getElementById('detail_option1');
    setCookie(optionEle);
    advanceCookieElement.classList.remove('cookie-not-set');
    setTimeout(() => {
      window.location.reload();
    }, 500);
  });
}

// *******************************************************************************
// 1.0 - Cookie banner details toggle
// *******************************************************************************

attachEvent('.advance-cookie-banner__detail-btn', 'click', (ev) => {
  const advanceDetailsBtn = ev.target.closest('.advance-cookie-banner');
  const advanceDetailsDropdown = advanceDetailsBtn.querySelector('.advance-cookie-banner__details');

  if (advanceDetailsBtn.classList.contains('is-open-details')) {
    slideUp(advanceDetailsDropdown, 400);
    advanceDetailsBtn.classList.remove('is-open-details');
  } else {
    advanceDetailsBtn.classList.add('is-open-details');
    slideDown(advanceDetailsDropdown, 400);
  }
});

// *******************************************************************************
// 1.2 - Open modal
// *******************************************************************************

attachEvent('.advance-cookie-modal-toggle-btn', 'click', (ev) => {
  ev.preventDefault();
  const cookieParentModalElem = ev.target.closest('.advance-cookie-banner-wrapper');
  cookieParentModalElem.querySelector('.advance-cookie-modal').classList.add('is-open-modal');
});

attachEvent('.advance-cookie-modal__close-btn', 'click', (ev) => {
  ev.preventDefault();
  const cookieEle = document.getElementById('cc-preference-selector-analytics');
  setCookie(cookieEle);
  ev.target.closest('.advance-cookie-modal').classList.remove('is-open-modal');
});

// *******************************************************************************
// 2.0 - Close Modal When Escape Key Press
// *******************************************************************************

document.addEventListener('keydown', (event) => {
  if (event.key === 'Escape') {
    const modalEle = document.querySelector('.advance-cookie-modal');
    modalEle.classList.remove('is-open-modal');
  }
});

// *******************************************************************************
// 3.0 - Close Modal When Click Out
// *******************************************************************************

document.addEventListener('click', (evt) => {
  const modalInner = document.querySelector('.advance-cookie-modal__inner');
  const toggleBtnEle = document.querySelector('.advance-cookie-modal-toggle-btn');
  let targetElement = evt.target; // clicked element

  do {
    if (targetElement === modalInner || targetElement === toggleBtnEle) {
      return;
    }
    targetElement = targetElement.parentNode;
  } while (targetElement);

  if (document.querySelector('.advance-cookie-modal')) {
    const modalEle = document.querySelector('.advance-cookie-modal');
    modalEle.classList.remove('is-open-modal');
  }
});
